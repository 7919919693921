html,
body,
#root {
    /* width: 100vw !important; */
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    /* overflow-x: hidden; */
}

/* html {
    scrollbar-gutter: stable;
} */

:root {
    --body-color: #F3F4F8;
    --primary-color: #1e73be;
    --secondary-color: #ffbf3f;
    --bg-color: #3a3a3a;
    --text-light-color: #ffffff;
    --text-dark-color: #3a3a3a;
    --border-color: #b9afaf;
}


body {
    background-color: var(--body-color);
}

del {
    color: var(--secondary-color);
}

/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #8e8e8e #ffffff;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: #ffffff;
}

*::-webkit-scrollbar-track:hover {
    background-color: #ffffff;
}

*::-webkit-scrollbar-track:active {
    background-color: #ffffff;
}

*::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #8e8e8e;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #878787;
}

*::-webkit-scrollbar-thumb:active {
    background-color: #8e8e8e;
}

a {
    text-decoration: none;
    color: var(--text-dark-color);
}

.breadcrumb a {
    color: white;
}

.footer a {
    color: #949494;
}

a:hover {
    color: var(--primary-color);
}

input {
    color: var(--text-dark-color);
}

/* for component layout  */
.component-container{
    min-height: 99vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}


#layout {
    width: 100%;
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-auto-rows: minmax(53px, auto);
    grid-template-areas:
        "nav nav"
        "leftsidebar maincontent"
    ;
}

nav {
    grid-area: nav;
}

#left-sidebar {
    grid-area: leftsidebar;
}

main {
    grid-area: maincontent;
}

.order-success-details a {
    color: var(--primary-color);
}


/* owl carousel */
/* .owl-carousel.owl-loaded , .owl-carousel .owl-stage-outer ,  .owl-stage , .owl-carousel .owl-item , .carouselimg {
    max-height: 561px;
} */
.owl-carousel .owl-stage-outer,
.owl-stage,
.owl-carousel .owl-item {
    height: 100%;
}

.carouseltext {
    z-index: 2;
}

.drawer-closed #layout {
    grid-template-columns: 0 1fr;
    /* Sidebar is hidden, main content takes full width */
}

#left-sidebar {
    transition: width 0.3s ease;
    /* Add transition for smooth animation */
}

main {
    grid-column: 2 / -1;
    /* Ensure main content takes full width when drawer is closed */
    transition: grid-column 0.3s ease;
    /* Add transition for smooth animation */
}

.drawer-closed main {
    grid-column: 1 / -1;
    /* Reset main content to take up remaining space when drawer is closed */
}

.drawer-open #layout {
    grid-template-columns: 250px 1fr;
    /* Sidebar is visible, main content takes remaining space */
}

.drawer-open main {
    grid-column: 2 / -1;
    width: 100%;
    /* Ensure main content takes full width when drawer is open */
}


/* search navbar */
.search-box-items {
    border: 1px solid var(--secondary-color);
}

/* cart drawer */
#cart-sidebar {
    position: absolute;
    top: 53px;
    right: 0;
}

.cart-button {
    opacity: .7;
    cursor: pointer;
    position: fixed;
    width: 65px;
    height: 75px;
    background: var(--bg-color);
    right: 2px;
    top: calc(110px + 30%);
    box-shadow: 0 0 16px -1px rgba(0, 0, 0, .75);
    transition: .1s ease-in-out;
    z-index: 3300;
    text-align: center;
    padding: 4px;
}

.cart-button:hover {
    opacity: 1;
}

.item-count {
    height: 50px;
    background: #55584d;
    width: 100%;
    color: var(--secondary-color);
}

.cart-button .total {
    color: var(--text-light-color);
}

.cart-drawer-header {
    background-color: #e4e0e1;
}

.cart-drawer-info {
    background-color: #7B797A;
    color: white;
}

.cart-p-picture {
    width: 60px;
    height: 60px;
}

.cart-p-picture img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.cart-drawer-items {
    border-top: 1px solid transparent;
    border-bottom: 1px solid #ebebeb;
    padding-top: 10px;
    padding-bottom: 10px;
}

.cart-coupon {
    background: #e3e3e3;
}

.cart-coupon input {
    background: #e3e3e3;
    border: 0;
    border-radius: 5px;
    height: 33px;
    width: 180px;
    outline: 0;
    padding: 0 10px;
}

.acordian-box {
    border: 1px solid #ededed;
}



/* livechat icon */

.livechaticon {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: var(--primary-color);
    display: grid;
    place-items: center;
    cursor: pointer;
    color: var(--text-light-color);
}

#live-chat {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 3301;
}

.messenger-box .close-icon,
.messenger-box .three-dot {
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--body-color);
    display: grid;
    place-items: center;
}

.messenger-box .external-links {
    background-color: var(--bg-color);
    border-radius: 12px;
    box-shadow: 0 12px 28px rgba(0, 0, 0, .2), 0 1px 4px rgba(0, 0, 0, .1);
    padding: 20px;
    width: 200px;
    position: absolute;
    bottom: -68px;
    right: 0;
    color: var(--text-light-color);
}


.popular-banner-img img {
    width: 100%;
    height: 100%;
}

.breadcrumb {
    /* height: 200px; */
    padding: 10px;
    background-color: var(--bg-color);
    color: var(--text-light-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.single-product-img {
    height: 150px;
}

.single-product-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.product-hover-items {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 77%;
    width: 90%;
    background-color: rgba(40, 40, 40, .75);
    display: none;
}

.product-hover-item {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: var(--bg-color);
    color: var(--text-light-color);
    display: grid;
    place-items: center;
    cursor: pointer;
    transition: transform 0.5s ease-in-out;
}

.product-hover-item:hover {
    background-color: var(--primary-color);
}

.single-product-box:hover .product-hover-items {
    display: block;
}

.single-product-box:hover,
.featured-prodct-box:hover,
.category-items:hover {
    transform: scale(1.05);
}


/* featured img */
.featured-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}



/* login dialog */
.login-or-container {
    width: 100%;
    text-align: center;
    position: relative;
    margin-top: 10px;
    margin-bottom: 30px;
}

/* .login-or-container::before{
    position: absolute;
    top: 8px;
    content: "";
    display: block;
    width: 100%;
    border-bottom: 1px solid var(--bg-color);
    z-index: -1;
} */

.login-or-container span {
    background: #ffffff;
    display: block;
    width: 50px;
    margin: 0 auto;
    font-size: 15px;
    z-index: 1;
}

.forgotp-policy a {
    color: var(--primary-color);
}

.product-list-details li {
    list-style: disc;
}

.number-count-button {
    border: 1px solid var(--bg-color);
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.small-img-box {
    width: 124px;
    height: 124px;
    cursor: pointer;
}

.quickview-img-box .img-container {
    max-height: 400px;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.quickview-img-box .img-container img {
    width: 80%;
    height: 80%;
    object-fit: contain;
}


.quickview-img-box,
.quickview-details {
    background-color: var(--text-light-color);
}

.checkout-order-details,
.checkout-aditional-info,
.checkout-details-form {
    background-color: var(--text-light-color);
    padding: 30px;
}

.checkout-details-form .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
}

.checkout-details-form .css-tbt8tp-MuiFormLabel-root-MuiInputLabel-root,
.checkout-aditional-info .css-tbt8tp-MuiFormLabel-root-MuiInputLabel-root {
    padding-bottom: 10px;
}

.checkout-aditional-info .css-143wqol-MuiInputBase-root-MuiOutlinedInput-root {
    height: inherit;
}

.payment-method {
    background-color: var(--body-color);
}

.description-review-tab {
    padding: 30px;
    background-color: var(--text-light-color);
    margin-top: 50px;
}


.checkout-aditional-info.MuiBox-root.css-0 {
    float: inherit;
    position: sticky;
    top: 80px;
}

/* footer */
.socialicon i {
    width: 40px;
    height: 40px;
    background-color: var(--bg-color);
    border-radius: 50%;
    cursor: pointer;
    display: grid;
    place-items: center;
    color: var(--text-light-color);
}

.socialicon i:hover {
    background-color: var(--primary-color);
}


/* mobile nav bottom  */
.mnb-icon {
    display: grid;
    place-items: center;
}

.mobile-nav-bottom {
    background-color: var(--body-color);
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9899;
    display: none;
}


/* info card component */
.categoryicon {
    width: 25%;
    margin-right: 2%;
}


/* product details page  */
.tabpanel-container ul li {
    list-style-type: disc;
}

.tabpanel-container ul {
    padding-left: 40px;
}

textarea#review-details {
    width: 100%;
    border-radius: 4px;
    border-color: var(--bg-color);
    padding: 20px;
    padding-right: 0;
}

textarea#review-details:focus {
    border-color: var(--primary-color);
    outline: none;
}

.styled-paper-for-img img {
    width: 126px;
    height: 126px;
}

.styled-paper-for-img {
    height: 210px;
}


.card-img-hover {
    overflow: hidden;
}

.card-img-hover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-img-hover:hover img {
    transform: scale(1.09);
}



/* seller */
.seller-details-image .image-wrapper img{
    width: 100%;
    height: 100%;
}

.authhighliter a {
    color: var(--primary-color);
}

.seller-auth-input input{
    padding-left: 10px;
}

.seller-auth-input label+.css-sw8fno-MuiInputBase-root-MuiInput-root{
    border-radius: 3px;
}

.seller-auth-input .MuiInputLabel-root {
    font-size: 18px; 
}
.profile-info-inner-texts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: flex-start;
    gap: 40px;
    padding-left: 30px;
}

.info-with-left-border {
    border-left: 0 solid rgba(0, 0, 0, 0.12);
    border-left-width: thin;
}


.custom-checkbox .MuiSvgIcon-root {
    font-size: 20px;
    padding: 0px 9px;
}

.custom-label {
    font-size: 15px;
}

.seller-product-filter{
    height: 100%;
    /* border-right: 1px solid rgba(0, 0, 0, 0.12); */
}



/* media quary */

@media only screen and (max-width: 1366px) {
    .drawer-open main {
        grid-column: 2 / -1;
        width: calc(100vw - 250px);
        /* Ensure main content takes full width when drawer is open */
    }

    body {
        overflow-x: hidden;
    }
}


@media only screen and (max-width: 1280px) {
    #layout {
        display: flex;
        flex-direction: column;
    }

    main {
        padding-top: 40px;
    }

    .drawer-open main {
        width: 100%;
    }

}

@media only screen and (max-width: 840px) {
    .info-with-left-border {
        border-left: none;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        margin-top: 25px;
    }
    

}


@media only screen and (max-width: 700px) {
    #layout {
        display: flex;
        flex-direction: column;
    }

    /* #left-sidebar{
        position: absolute;
        top: 53px;
        left: 0;
    } */

    .single-product-box:hover {
        transform: none;
    }

    #live-chat {
        display: none;
    }

    .mobile-nav-bottom {
        display: flex;
    }

    .cart-button {
        display: none;
    }

    .toolbar-custom-layout {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-bottom: 20px;
    }

    .toolbar-custom-layout :nth-child(1) {
        order: 1;
    }

    .toolbar-custom-layout :nth-child(2) {
        order: 2;
    }

    .toolbar-custom-layout :nth-child(3) {
        order: 4;
        width: 100%;
        margin-top: 8px;
        margin-left: 0;
    }

    .toolbar-custom-layout :nth-child(4) {
        order: 3;
    }

    .for-mobile-container {
        margin-top: 100px;
    }
    .css-4pdmu4-MuiBreadcrumbs-ol{
        justify-content: center;
    }

}


@media only screen and (max-width: 370px) {
    .category-swiper-wrapper {
        gap: 10px;
    }

    .styled-paper-for-img img {
        width: 100px;
        height: 100px;
    }

    .styled-paper-for-img {
        height: 160px;
    }
}


.layout-container-for-smalldata {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 99vh;
}



/* product details slider */
.p-details-only.card-wrapper{
    max-width: 1100px;
    margin: 0 auto;
}
.p-details-only img{
    width: 100%;
    display: block;
}
.p-details-only .img-display{
    overflow: hidden;
}
.p-details-only .img-showcase{
    display: flex;
    width: 100%;
    transition: all 0.5s ease;
}
.p-details-only .img-showcase img{
    min-width: 100%;
    max-height: 400px;
    object-fit: cover;
}
.p-details-only .img-select{
    display: flex;
}
.p-details-only .img-item{
    margin: 0.3rem;
}
.p-details-only .img-item:nth-child(1),
.p-details-only .img-item:nth-child(2),
.p-details-only .img-item:nth-child(3){
    margin-right: 0;
}
.p-details-only .img-item:hover{
    opacity: 0.8;
}
.p-details-only .product-content{
    padding: 2rem 1rem;
}
.p-details-only .product-title{
    font-size: 3rem;
    text-transform: capitalize;
    font-weight: 700;
    position: relative;
    color: #12263a;
    margin: 1rem 0;
}
.p-details-only .product-title::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: 80px;
    background: #12263a;
}
.p-details-only .product-link{
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.9rem;
    display: inline-block;
    margin-bottom: 0.5rem;
    background: #256eff;
    color: #fff;
    padding: 0 0.3rem;
    transition: all 0.5s ease;
}
.p-details-only .product-link:hover{
    opacity: 0.9;
}
.p-details-only .product-rating{
    color: #ffc107;
}
.p-details-only .product-rating span{
    font-weight: 600;
    color: #252525;
}
.p-details-only .product-price{
    margin: 1rem 0;
    font-size: 1rem;
    font-weight: 700;
}
.p-details-only .product-price span{
    font-weight: 400;
}
.p-details-only .last-price span{
    color: #f64749;
    text-decoration: line-through;
}
.p-details-only .new-price span{
    color: #256eff;
}
.p-details-only .product-detail h2{
    text-transform: capitalize;
    color: #12263a;
    padding-bottom: 0.6rem;
}
.p-details-only .product-detail p{
    font-size: 0.9rem;
    padding: 0.3rem;
    opacity: 0.8;
}
.p-details-only .product-detail ul{
    margin: 1rem 0;
    font-size: 0.9rem;
}
.p-details-only .product-detail ul li{
    margin: 0;
    list-style: none;
    background: url(https://fadzrinmadu.github.io/hosted-assets/product-detail-page-design-with-image-slider-html-css-and-javascript/checked.png) left center no-repeat;
    background-size: 18px;
    padding-left: 1.7rem;
    margin: 0.4rem 0;
    font-weight: 600;
    opacity: 0.9;
}
.p-details-only .product-detail ul li span{
    font-weight: 400;
}
.p-details-only .purchase-info{
    margin: 1.5rem 0;
}
.p-details-only .purchase-info input,
.p-details-only .purchase-info .btn{
    border: 1.5px solid #ddd;
    border-radius: 25px;
    text-align: center;
    padding: 0.45rem 0.8rem;
    outline: 0;
    margin-right: 0.2rem;
    margin-bottom: 1rem;
}
.p-details-only .purchase-info input{
    width: 60px;
}
.p-details-only .purchase-info .btn{
    cursor: pointer;
    color: #fff;
}
.p-details-only .purchase-info .btn:first-of-type{
    background: #256eff;
}
.p-details-only .purchase-info .btn:last-of-type{
    background: #f64749;
}
.p-details-only .purchase-info .btn:hover{
    opacity: 0.9;
}
.p-details-only .social-links{
    display: flex;
    align-items: center;
}
.p-details-only .social-links a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: #000;
    border: 1px solid #000;
    margin: 0 0.2rem;
    border-radius: 50%;
    text-decoration: none;
    font-size: 0.8rem;
    transition: all 0.5s ease;
}
.p-details-only .social-links a:hover{
    background: #000;
    border-color: transparent;
    color: #fff;
}

.p-details-only .card{
    margin: 30px 0;
}

@media screen and (min-width: 992px){
    .p-details-only .card{
        margin: 60px 0px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5rem;
    }
    .p-details-only.card-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .p-details-only .product-imgs{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .p-details-only .product-content{
        padding-top: 0;
    }

    .p-details-only .img-showcase img {
        object-fit: cover;
        max-height: 100%;
    }
}