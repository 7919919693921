ul{
    margin-left: 0;
    padding-left: 0;
    margin-top: 5px;
}

li{
    list-style: none;
    margin: 5px 0;
}

a{
    text-decoration: none;
}

.paymenticon img{
    width: 35px;
    height: 35px;
}

.paymenticon{
    display: flex;
    align-items: center;
}

.getappinputbox{
    font-size: 10px;
    border: 0.5px solid #9b60e8;
    background-color: #fff;
    border-radius: 10px;
    align-items: baseline;
    display: flex;
    justify-content: flex-end;
    padding-left: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.socialicon  img{
    width: 40px;
    height: 40px;
}