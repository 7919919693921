.p-details-only.card-wrapper{
    max-width: 1100px;
    margin: 0 auto;
}
.p-details-only img{
    width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
}
.p-details-only .img-display{
    overflow: hidden;
}
.p-details-only .img-showcase{
    display: flex;
    height: 450px;
    width: 100%;
    transition: all 0.5s ease;
}
.p-details-only .img-showcase img{
    min-width: 100%;
    max-height: 400px;
    object-fit: contain;
}
.p-details-only .img-select{
    display: flex;
    max-height: 150px;
    justify-content: flex-start;
}
.p-details-only .img-item{
    margin: 0.3rem;
    max-width: 100px;
}
.p-details-only .img-item:nth-child(1),
.p-details-only .img-item:nth-child(2),
.p-details-only .img-item:nth-child(3){
    margin-right: 0;
}
.p-details-only .img-item:hover{
    opacity: 0.8;
}
.p-details-only .product-content{
    padding: 2rem 1rem;
}
.p-details-only .product-title{
    font-size: 3rem;
    text-transform: capitalize;
    font-weight: 700;
    position: relative;
    color: #12263a;
    margin: 1rem 0;
}
.p-details-only .product-title::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: 80px;
    background: #12263a;
}
.p-details-only .product-link{
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.9rem;
    display: inline-block;
    margin-bottom: 0.5rem;
    background: #256eff;
    color: #fff;
    padding: 0 0.3rem;
    transition: all 0.5s ease;
}
.p-details-only .product-link:hover{
    opacity: 0.9;
}
.p-details-only .product-rating{
    color: #ffc107;
}
.p-details-only .product-rating span{
    font-weight: 600;
    color: #252525;
}
.p-details-only .product-price{
    margin: 1rem 0;
    font-size: 1rem;
    font-weight: 700;
}
.p-details-only .product-price span{
    font-weight: 400;
}
.p-details-only .last-price span{
    color: #f64749;
    text-decoration: line-through;
}
.p-details-only .new-price span{
    color: #256eff;
}
.p-details-only .product-detail h2{
    text-transform: capitalize;
    color: #12263a;
    padding-bottom: 0.6rem;
}
.p-details-only .product-detail p{
    font-size: 0.9rem;
    padding: 0.3rem;
    opacity: 0.8;
}
.p-details-only .product-detail ul{
    margin: 1rem 0;
    font-size: 0.9rem;
}
.p-details-only .product-detail ul li{
    margin: 0;
    list-style: none;
    background: url(https://fadzrinmadu.github.io/hosted-assets/product-detail-page-design-with-image-slider-html-css-and-javascript/checked.png) left center no-repeat;
    background-size: 18px;
    padding-left: 1.7rem;
    margin: 0.4rem 0;
    font-weight: 600;
    opacity: 0.9;
}
.p-details-only .product-detail ul li span{
    font-weight: 400;
}
.p-details-only .purchase-info{
    margin: 1.5rem 0;
}
.p-details-only .purchase-info input,
.p-details-only .purchase-info .btn{
    border: 1.5px solid #ddd;
    border-radius: 25px;
    text-align: center;
    padding: 0.45rem 0.8rem;
    outline: 0;
    margin-right: 0.2rem;
    margin-bottom: 1rem;
}
.p-details-only .purchase-info input{
    width: 60px;
}
.p-details-only .purchase-info .btn{
    cursor: pointer;
    color: #fff;
}
.p-details-only .purchase-info .btn:first-of-type{
    background: #256eff;
}
.p-details-only .purchase-info .btn:last-of-type{
    background: #f64749;
}
.p-details-only .purchase-info .btn:hover{
    opacity: 0.9;
}
.p-details-only .social-links{
    display: flex;
    align-items: center;
}
.p-details-only .social-links a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: #000;
    border: 1px solid #000;
    margin: 0 0.2rem;
    border-radius: 50%;
    text-decoration: none;
    font-size: 0.8rem;
    transition: all 0.5s ease;
}
.p-details-only .social-links a:hover{
    background: #000;
    border-color: transparent;
    color: #fff;
}

.p-details-only .card{
    margin: 30px 0;
}

@media screen and (min-width: 992px){
    .p-details-only .card{
        margin: 60px 0px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5rem;
    }
    .p-details-only.card-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .p-details-only .product-imgs{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .p-details-only .product-content{
        padding-top: 0;
    }

    .p-details-only .img-showcase img {
        object-fit: contain;
        max-height: 100%;
    }

}


@media screen and (max-width: 1400px){
    .p-details-only .img-showcase {
        height: 350px;
    }
}
@media screen and (max-width: 992px){
    .p-details-only .img-showcase {
        height: 300px;
    }
}